import React, { useState, useCallback, useContext } from 'react';
import { LoadingSpinner, BottomNavigations } from '../../components';
import { useInterval } from '../../hooks';
import axios from '../../utils/api';
import {
	GlobalContainerParticipants,
	HeaderParticipants,
	ParticipantsContainer,
	LoaderParticipants,
	FooterParticipants,
	Text,
} from './styles';
import Box from '@mui/material/Box';
import { useSnackbar } from 'notistack';
import { currentSessionContext } from '../../contexts/currentSession';
import { useTranslation } from 'react-i18next';

function Participants() {
	const { enqueueSnackbar } = useSnackbar();

	const [isLoading, setIsLoading] = useState(true);

	const [data, setData] = useState(null);

	const { currentSession } = useContext(currentSessionContext);

	const { i18n } = useTranslation();

	const handleRequest = useCallback(async () => {
		if (currentSession) {
			try {
				const response = await axios.get(
					`/api/session/${currentSession.codeOnline}/participants`
				);

				if (response.status === 200) {
					setData(response.data);
				} else {
					enqueueSnackbar(`${i18n.t('errorGettingData')}`, {
						variant: 'error',
					});
				}
			} catch (e) {
				enqueueSnackbar(`${i18n.t('errorGettingData')}`, { variant: 'error' });
			} finally {
				setIsLoading(false);
			}
		} else {
			setData(null);
			setIsLoading(false);
		}
	}, [currentSession, enqueueSnackbar, i18n]);

	useInterval({ callback: handleRequest, callOnInit: true });

	return (
		<GlobalContainerParticipants>
			<HeaderParticipants>{`${i18n.t('participants')}`}</HeaderParticipants>
			<ParticipantsContainer>
				<LoaderParticipants>
					{isLoading ? (
						<LoadingSpinner />
					) : (
						<Box
							sx={{
								paddingRight: 5,
								paddingLeft: 5,
								width: '100%',
							}}
						>
							{data?.length > 0 ? (
								<>
									<Text sx={{ fontWeight: 'bold', paddingBottom: 2 }}>
										{`${i18n.t('participants')}`} &nbsp;
										{data.length}/{data.length}
									</Text>
									<Box
										sx={{
											display: 'flex',
											flexDirection: 'column',
											height: 300,
											overflow: 'auto',
											paddingBottom: 2,
										}}
									>
										{data.map((user) => {
											return (
												<Box key={user.id}>
													<Text>{user.nameToDisplay}</Text>
												</Box>
											);
										})}
									</Box>
								</>
							) : (
								<Text sx={{ textAlign: 'center' }}>
									{`${i18n.t('noParticipantsFound')}`}
								</Text>
							)}
						</Box>
					)}
				</LoaderParticipants>
			</ParticipantsContainer>
			<FooterParticipants>
				<BottomNavigations current="participants" />
			</FooterParticipants>
		</GlobalContainerParticipants>
	);
}

export default Participants;
