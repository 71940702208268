import { useContext, useCallback } from 'react';
import { useSnackbar } from 'notistack';
import { currentSessionContext } from '../contexts/currentSession';
import { AuthContext } from '../contexts/auth';
import axios from '../utils/api';
import { useInterval } from '.';
import { useTranslation } from 'react-i18next';

async function useCurrentSessionAutomaticUpdate() {
	const i18n = useTranslation();

	const { enqueueSnackbar } = useSnackbar();

	const { setCurrentSession } = useContext(currentSessionContext);

	const { signOut } = useContext(AuthContext);

	const handleRequest = useCallback(async () => {
		try {
			const response = await axios.get(
				`/api/sessions?currentlyInUseAndAnimatedBy=me`
			);

			if (response.status === 200) {
				const session = response.data[0];

				if (session) {
					setCurrentSession(session);
				} else {
					setCurrentSession(null);
				}
			} else {
				throw new Error();
			}
		} catch (e) {
			if (e.response.status === 401) {
				enqueueSnackbar(`${i18n.t('nonValidSession')}`, { variant: 'info' });

				signOut();
			} else {
				enqueueSnackbar(`${i18n.t('errorGettingData')}`, {
					variant: 'error',
				});
			}
		}
	}, [enqueueSnackbar, i18n, setCurrentSession, signOut]);

	useInterval({ callback: handleRequest });
}

export default useCurrentSessionAutomaticUpdate;
