import React, { useContext } from 'react';
import { BottomNavigations } from '../../components';
import {
	GlobalContainerMain,
	HeaderMain,
	MainContainer,
	LoaderMain,
	FooterMain,
	Text,
	ElementContainer,
} from './styles';
import Box from '@mui/material/Box';
import { currentSessionContext } from '../../contexts/currentSession';
import packageJson from '../../../package.json';
import { useTranslation } from 'react-i18next';

function Main() {
	const { currentSession } = useContext(currentSessionContext);

	const { i18n } = useTranslation();

	const ButtonMailto = () => {
		return (
			<a
				style={{
					textDecorationColor: 'gray',
				}}
				href="mailto:support@quizzbox.com"
			>
				<Text>{`${i18n.t('contactSupport')}`}</Text>
			</a>
		);
	};

	return (
		<GlobalContainerMain>
			<HeaderMain>{`${i18n.t('main')}`}</HeaderMain>
			<MainContainer>
				<LoaderMain>
					<Box
						sx={{
							padding: 5,
							width: '100%',
						}}
					>
						{currentSession ? (
							<Box>
								<ElementContainer>
									<Text>{`${i18n.t('hello')}`} : &nbsp;</Text>
									<Text>
										{currentSession?.userEmail}
										{'\n'}
									</Text>
								</ElementContainer>

								<ElementContainer>
									<Text>{`${i18n.t('actualSession')}`} : &nbsp;</Text>
									<Text sx={{ color: 'snackBarSuccess' }}>
										{currentSession?.codeOnline}
									</Text>
								</ElementContainer>

								<ElementContainer>
									<Text>{`${i18n.t('scenario')}`} : &nbsp;</Text>
									<Text> {currentSession?.scenario}</Text>
								</ElementContainer>
							</Box>
						) : (
							<Box>
								<Text
									sx={{
										whiteSpace: 'pre-wrap',
										textAlign: 'justify',
									}}
								>
									{`${i18n.t('noSessionDetected')}`}
								</Text>
								<ButtonMailto />
							</Box>
						)}
					</Box>
				</LoaderMain>
			</MainContainer>
			<FooterMain>
				<Text sx={{ textAlign: 'center', position: 'absolute', bottom: 10 }}>
					{`${i18n.t('version')}`} {packageJson.version}
				</Text>
				<BottomNavigations current="main" />
			</FooterMain>
		</GlobalContainerMain>
	);
}

export default Main;
