import React from 'react';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import { NavBarElement } from './styles';
import InputLabel from '@mui/material/InputLabel';

const TopBar = ({ codeOnline }) => {
	const i18n = useTranslation();

	return (
		<NavBarElement>
			<Box
				sx={{
					marginLeft: '16px',
					height: '25px',
					width: '25px',
					backgroundColor: codeOnline ? '#3CB371' : 'gray',
					borderRadius: '50%',
					position: 'absolute',
				}}
			></Box>
			<InputLabel
				sx={{
					color: codeOnline ? '#3CB371' : 'gray',
					marginLeft: '32px',
				}}
			>
				{i18n.t(codeOnline ? 'session' : 'noActiveSession')}
				{codeOnline ? codeOnline : null}
			</InputLabel>
		</NavBarElement>
	);
};

export default TopBar;
