import React from 'react';
import { Container } from './styles';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import HomeIcon from '@mui/icons-material/Home';
import GroupsIcon from '@mui/icons-material/Groups';
import SettingsRemoteIcon from '@mui/icons-material/SettingsRemote';
import SettingsIcon from '@mui/icons-material/Settings';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const BottomNavigations = (props) => {
	const navigate = useNavigate();

	const { i18n } = useTranslation();

	const handleChange = (newValue) => {
		switch (newValue) {
			case 'main': {
				navigate('/main');
				break;
			}
			case 'remote': {
				navigate('/remote');
				break;
			}
			case 'participants': {
				navigate('/participants');
				break;
			}
			case 'settings': {
				navigate('/settings');
				break;
			}
			default:
				navigate('/main');
		}
	};
	return (
		<Container>
			<BottomNavigation
				sx={{ bgcolor: 'bottomNavigator.main', borderRadius: 8 }}
				value={props.current}
				onChange={(e, newValue) => handleChange(newValue)}
			>
				<BottomNavigationAction
					label={`${i18n.t('main')}`}
					value="main"
					icon={<HomeIcon />}
				/>
				<BottomNavigationAction
					label={`${i18n.t('participantsBtn')}`}
					value="participants"
					icon={<GroupsIcon />}
				/>
				<BottomNavigationAction
					label={`${i18n.t('remote')}`}
					value="remote"
					icon={<SettingsRemoteIcon />}
				/>
				<BottomNavigationAction
					label={`${i18n.t('settings')}`}
					value="settings"
					icon={<SettingsIcon />}
				/>
			</BottomNavigation>
		</Container>
	);
};

export default BottomNavigations;
