import React, { useContext } from 'react';
import { AuthContext } from '../../contexts/auth';
import { AuthenticatedApp, UnauthenticatedApp } from '../../components';

const Home = () => {
	const { isSigned } = useContext(AuthContext);
	return isSigned ? <AuthenticatedApp /> : <UnauthenticatedApp />;
};

export default Home;
