import React, { useContext, useState, useEffect } from 'react';
import {
	BtnElement,
	RemoteButton,
	BtnText,
	RemoteButtonElement,
} from './styles';
import { useSnackbar } from 'notistack';
import axios from '../../utils/api';
import { useTranslation } from 'react-i18next';
import { ThemeContext } from '../../contexts/themeContext';

import { currentSessionContext } from '../../contexts/currentSession';

const CommandButton = (props) => {
	const i18n = useTranslation();

	const { enqueueSnackbar } = useSnackbar();

	const { currentSession } = useContext(currentSessionContext);

	const { currentTheme } = useContext(ThemeContext);

	const [buttonDisabled, setbuttonDisabled] = useState(false);

	useEffect(() => {
		if (currentSession?.codeOnline) {
			setbuttonDisabled(false);
		} else {
			setbuttonDisabled(true);
		}
	}, [currentSession]);

	const sendCommand = async (id) => {
		setbuttonDisabled(true);

		try {
			const result = await axios.post(
				`/api/session/${currentSession.codeOnline}/admin-remote-command`,
				{
					action: id,
				}
			);

			if (result.status !== 204) {
				enqueueSnackbar(`${i18n.t('errorCommand')}`, { variant: 'error' });
			}
		} catch (e) {
			enqueueSnackbar(`${i18n.t('errorGettingData')}`, { variant: 'error' });
		}
		setbuttonDisabled(false);
	};
	return (
		<RemoteButton
			sx={{
				backgroundColor: 'backGround.main',
				opacity: buttonDisabled ? 0.5 : 1,
				pointerEvents: buttonDisabled ? 'none' : 'auto',
			}}
			onClick={() => sendCommand(props.id)}
			boxShadowIsActive={currentTheme === 'light'}
		>
			{props.alignRight ? (
				<RemoteButtonElement>
					<BtnElement>{props.icon}</BtnElement>
					<BtnText>{props.text}</BtnText>
				</RemoteButtonElement>
			) : (
				<RemoteButtonElement>
					<BtnElement>{props.icon}</BtnElement>
					<BtnText>{props.text}</BtnText>
				</RemoteButtonElement>
			)}
		</RemoteButton>
	);
};

export default CommandButton;
