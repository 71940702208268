import React, { useContext, useState } from 'react';
import {
	GlobalContainerSettings,
	HeaderSettings,
	MainSettings,
	MenuItem,
	FooterSettings,
	BtnSettingsText,
	MenuItemLogout,
	LabelText,
	MenuDivider,
} from './styles';
import { BottomNavigations } from '../../components';
import { ThemeContext } from '../../contexts/themeContext';
import { AuthContext } from '../../contexts/auth';
import { useTranslation } from 'react-i18next';
import FormControl from '@mui/material/FormControl';
import { Select } from '@mui/material';
import DarkModeIcon from '@mui/icons-material/DarkMode';
import LanguageIcon from '@mui/icons-material/Language';
import Switch from '@mui/material/Switch';

function Settings() {
	const { signOut } = useContext(AuthContext);

	const { toggleTheme, currentTheme } = useContext(ThemeContext);

	const [selectIsOpen, setSelectIsOpen] = useState(false);

	const { i18n } = useTranslation();

	const languages = {
		en: { nativeName: 'English' },
		de: { nativeName: 'Deutsch' },
		fr: { nativeName: 'French' },
	};

	return (
		<GlobalContainerSettings>
			<HeaderSettings>{`${i18n.t('settings')}`}</HeaderSettings>
			<MainSettings>
				<MenuItem>
					<DarkModeIcon />
					<BtnSettingsText sx={{ color: 'primary.main' }}>
						<LabelText
							sx={{ paddingLeft: '14px', color: 'primary.main' }}
						>{`${i18n.t('themeMode')}`}</LabelText>
					</BtnSettingsText>
					<Switch
						checked={currentTheme === 'dark'}
						onChange={toggleTheme}
						inputProps={{ 'aria-label': 'controlled' }}
					/>
				</MenuItem>
				<MenuDivider
					variant="middle"
					sx={{
						width: '70vw',
						margin: 'auto',
					}}
				/>
				<MenuItem
					onClick={() => {
						setSelectIsOpen(!selectIsOpen);
					}}
				>
					<LanguageIcon />
					<BtnSettingsText
						sx={{
							color: 'primary.main',
						}}
					>
						<FormControl
							sx={{
								width: '100%',
								position: 'sticky',
								bottom: 140,
								'.MuiOutlinedInput-notchedOutline': {
									borderWidth: '0px',
								},
							}}
						>
							<Select
								sx={{
									width: '60%',
									alignSelf: 'center',
									paddingRight: 5,
									'.MuiOutlinedInput-notchedOutline': {
										borderColor: 'transparent',
									},
									'&.Mui-focused .MuiOutlinedInput-notchedOutline': {
										borderColor: 'transparent',
									},
									'&:hover .MuiOutlinedInput-notchedOutline': {
										borderColor: 'transparent',
									},
								}}
								open={selectIsOpen}
								inputProps={{ IconComponent: () => null }}
							>
								{Object.keys(languages).map((language) => (
									<MenuItem
										sx={{
											justifyContent: 'center',
											'&:hover': {
												backgroundColor: 'lightgray',
											},
										}}
										variant="contained"
										type="submit"
										key={language}
										value={i18n.language.nativeName}
										onClick={() => {
											i18n.changeLanguage(language);
											setSelectIsOpen(false);
										}}
										disabled={i18n.resolvedLanguage === language}
									>
										{languages[language].nativeName}
									</MenuItem>
								))}
							</Select>

							<LabelText sx={{ color: 'primary.main' }}>{`${i18n.t(
								'language'
							)}`}</LabelText>
						</FormControl>
					</BtnSettingsText>
				</MenuItem>
				<MenuItemLogout sx={{ backgroundColor: 'backGround.main' }}>
					<BtnSettingsText sx={{ color: 'primary.main' }} onClick={signOut}>
						<LabelText
							sx={{
								margin: 'auto',
								width: '60%',
								textAlign: 'center',
								color: 'primary.main',
							}}
						>{`${i18n.t('logout')}`}</LabelText>
					</BtnSettingsText>
				</MenuItemLogout>
			</MainSettings>

			<FooterSettings>
				<BottomNavigations current="settings" />
			</FooterSettings>
		</GlobalContainerSettings>
	);
}

export default Settings;
