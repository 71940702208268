import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import { styled } from '@mui/material/styles';

export const GlobalContainerParticipants = styled(Box)({
	height: '90vh',
});

export const HeaderParticipants = styled(Box)({
	minHeight: '8vh',
	color: 'orange',
	fontSize: '30px',
	fontWeight: 'bold',
	padding: '65px',
});

export const ParticipantsContainer = styled(Box)({
	height: '60vh',
	alignItems: 'flex-start',
	justifyContent: 'flex-start',
	textAlign: 'left',
});

export const LoaderParticipants = styled(Box)({
	display: 'flex',
	flexWrap: 'wrap',
	justifyContent: 'center',
});

export const FooterParticipants = styled(Box)({
	height: '10vh',
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
});

export const Text = styled(InputLabel)({
	fontSize: '15px',
	paddingTop: '8px',
});
