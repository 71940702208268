import React from 'react';
import {
	BrowserRouter as Router,
	Route,
	Routes,
	Navigate,
} from 'react-router-dom';
import { Login } from '../../screens';

const UnauthenticatedApp = () => {
	return (
		<>
			<Router>
				<Routes>
					<Route path="/" element={<Login />}></Route>
					<Route path="/*" element={<Navigate to="/" />}></Route>
				</Routes>
			</Router>
		</>
	);
};

export default UnauthenticatedApp;
