import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import { styled } from '@mui/material/styles';

export const GlobalContainerMain = styled(Box)({
	height: '90vh',
});

export const HeaderMain = styled(Box)({
	minHeight: '10vh',
	color: 'orange',
	fontSize: '30px',
	fontWeight: 'bold',
	padding: '65px',
});

export const MainContainer = styled(Box)({
	height: '60vh',
	alignItems: 'flex-start',
	justifyContent: 'flex-start',
	textAlign: 'left',
});

export const ElementContainer = styled(Box)({
	display: 'flex',
	flexDirection: 'row',
	width: '100%',
});

export const LoaderMain = styled(Box)({
	display: 'flex',
	flexWrap: 'wrap',
	justifyContent: 'center',
	paddingTop: '70px',
});

export const FooterMain = styled(Box)({
	height: '10vh',
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
});

export const Text = styled(InputLabel)({
	fontSize: '15px',
	paddingTop: '8px',
});
