import React, { useContext } from 'react';
import { AuthContext } from '../../contexts/auth';
import {
	HeaderLogin,
	TextfiedsBox,
	InputTextBox,
	GlobalContainerLogin,
	LoginContainer,
	BtnCnxn,
	BtnCnxnLoading,
	TextLegals,
	Legals,
} from './styles';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { Formik } from 'formik';
import { LoadingSpinner } from '../../components';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';

import appLogo from '../../assets/LogoQuizzbox.png';

function Login() {
	const i18n = useTranslation();

	const { enqueueSnackbar } = useSnackbar();

	const { signIn } = useContext(AuthContext);

	const navigate = useNavigate();

	const handleSubmitForm = async (values) => {
		try {
			const res = await signIn(values);

			if (res.status === 200) {
				if (res.data.success !== true) {
					enqueueSnackbar(`${i18n.t('wrongCredentials')}`, {
						variant: 'error',
					});
				} else {
					enqueueSnackbar(`${i18n.t('successConnection')}`, {
						variant: 'success',
					});

					navigate('/remote');
				}
			}
		} catch (e) {
			enqueueSnackbar(`${i18n.t('errorGettingData')}`, { variant: 'error' });
		}
	};

	return (
		<GlobalContainerLogin>
			<HeaderLogin>
				<img
					object-fit="contain"
					src={appLogo}
					width="300"
					height="95"
					alt="logo"
				/>
			</HeaderLogin>
			<LoginContainer>
				<Formik
					initialValues={{
						email: '',
						password: '',
					}}
					onSubmit={(values) => handleSubmitForm(values)}
				>
					{({ handleChange, handleSubmit, values, isValid, isSubmitting }) => {
						return (
							<TextfiedsBox>
								<Box
									sx={{
										display: 'flex',
										flexWrap: 'wrap',
										flexDirection: 'column',
										alignContent: 'center',
									}}
								>
									<InputTextBox>
										<TextField
											id="Email"
											label={`${i18n.t('email')}`}
											variant="outlined"
											type="email"
											fullWidth
											onChange={handleChange('email')}
											value={values.email}
										/>
									</InputTextBox>

									<InputTextBox>
										<TextField
											id="pass"
											label={`${i18n.t('password')}`}
											variant="outlined"
											type="password"
											fullWidth
											onChange={handleChange('password')}
											value={values.password}
											onKeyDown={(e) => {
												if (e.key === 'Enter') {
													handleSubmit();
												}
											}}
										/>
									</InputTextBox>
								</Box>

								<BtnCnxn>
									<Button
										sx={{
											backgroundColor: 'buttons.main',
											color: 'buttonsTextColors.main',
											width: {
												xs: '40vw',
												sm: '25vw',
												md: '20vw',
												lg: '15vw',
												xl: '10vw',
											},
										}}
										disabled={
											!isValid ||
											isSubmitting ||
											values.email === '' ||
											values.password === ''
										}
										variant="contained"
										type="submit"
										onClick={handleSubmit}
									>
										{`${i18n.t('connection')}`}
									</Button>

									<BtnCnxnLoading>
										{isSubmitting && <LoadingSpinner />}
									</BtnCnxnLoading>
								</BtnCnxn>
								<Legals>
									<a
										style={{
											textDecorationColor: 'gray',
										}}
										href="https://quizzbox.com/en/legal-notice/"
									>
										<TextLegals>{`${i18n.t('legals')}`}</TextLegals>
									</a>
								</Legals>
							</TextfiedsBox>
						);
					}}
				</Formik>
			</LoginContainer>
		</GlobalContainerLogin>
	);
}

export default Login;
