import React, { createContext, useState, useEffect } from 'react';

const ThemeContext = createContext();

const STORAGE_THEMEMODE = 'theme';

function ThemeProvider({ children }) {
	const [theme, setTheme] = useState(
		localStorage.getItem(STORAGE_THEMEMODE)
			? localStorage.getItem(STORAGE_THEMEMODE)
			: 'light'
	);

	useEffect(() => {
		localStorage.setItem(STORAGE_THEMEMODE, theme);
	}, [theme]);

	// To toggle between dark and light modes
	const toggleTheme = () => {
		setTheme(theme === 'dark' ? 'light' : 'dark');
	};

	return (
		<ThemeContext.Provider value={{ currentTheme: theme, toggleTheme }}>
			{children}
		</ThemeContext.Provider>
	);
}

export { ThemeProvider, ThemeContext };
