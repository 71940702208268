import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';

export const Separator = styled(Box)({
	minHeight: '5vh',
	borderBottom: '1px solid #C7C7C7',
	width: '90vw',
	margin: 'auto',
	'@media (min-width: 768px)': {
		minHeight: '10vh',
	},
	'@media (min-width: 1040px)': {
		width: '70vw',
	},
});

export const MainRemote = styled(Box)({
	paddingTop: '2rem',
	'@media (min-width: 768px)': {
		paddingTop: '4rem',
	},
});

export const Segmentbuttons = styled(Box)({
	width: '100vw',
	display: 'flex',
	flexDirection: 'row',
	justifyContent: 'space-evenly',
	marginBottom: '5px',
	'@media (min-width: 1040px)': {
		justifyContent: 'center',
	},
});

export const FooterRemote = styled(Box)({
	height: '10vh',
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
});
