import React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import { AuthProvider } from './contexts/auth';
import { SnackbarProvider } from 'notistack';
import { CurrentSessionProvider } from './contexts/currentSession';
import { Home } from './components';
import { useTheme } from './hooks';
import './translation/translation';

function App() {
	const theme = useTheme();

	return (
		<ThemeProvider theme={theme}>
			<CssBaseline />
			<SnackbarProvider
				autoHideDuration={3000}
				anchorOrigin={{
					vertical: 'top',
					horizontal: 'center',
				}}
			>
				<AuthProvider>
					<CurrentSessionProvider>
						<Home />
					</CurrentSessionProvider>
				</AuthProvider>
			</SnackbarProvider>
		</ThemeProvider>
	);
}

export default App;
