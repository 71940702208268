import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import { Divider } from '@mui/material';
import { styled } from '@mui/material/styles';

export const GlobalContainerSettings = styled(Box)({
	height: '90vh',
	flexDirection: 'row',
});

export const HeaderSettings = styled(Box)({
	minHeight: '10vh',
	color: 'orange',
	fontSize: '30px',
	fontWeight: 'bold',
	padding: '65px',
});

export const MainSettings = styled(Box)({
	height: '40vh',
});

export const MenuItem = styled(Box)({
	'@media only screen and (max-width: 600px)': {
		width: '90%',
	},

	/* Small devices (portrait tablets and large phones, 600px and up) */
	'@media only screen and (min-width: 600px)': {
		width: '75%',
	},

	/* Medium devices (landscape tablets, 768px and up) */
	'@media only screen and (min-width: 768px)': {
		width: '60%',
	},

	/* Large devices (laptops/desktops, 992px and up) */
	'@media only screen and (min-width: 992px)': {
		width: '50%',
	},

	/* Extra large devices (large laptops and desktops, 1200px and up) */
	'@media only screen and (min-width: 1200px)': {
		width: '55%',
	},
	marginBottom: '14px',
	height: '80px',
	display: 'flex',
	flexDirection: 'row',
	justifyContent: 'space-between',
	alignItems: 'center',
	marginLeft: 'auto',
	marginRight: 'auto',
	padding: '20px',
	borderWidth: '1px',
	borderRadius: '10px',
	borderColor: 'black',
});

export const MenuItemLanguage = styled(Box)({
	width: '200px',
	height: '24px',
	display: 'flex',
	flexDirection: 'row',
	justifyContent: 'space-between',
	alignItems: 'center',
	marginLeft: 'auto',
	marginRight: 'auto',
	borderWidth: '1px',
	borderRadius: '10px',
	borderColor: 'black',
});

export const MenuDivider = styled(Divider)({
	'@media only screen and (max-width: 600px)': {
		width: '77%',
	},

	/* Small devices (portrait tablets and large phones, 600px and up) */
	'@media only screen and (min-width: 600px)': {
		width: '68%',
	},

	/* Medium devices (landscape tablets, 768px and up) */
	'@media only screen and (min-width: 768px)': {
		width: '55%',
	},

	/* Large devices (laptops/desktops, 992px and up) */
	'@media only screen and (min-width: 992px)': {
		width: '45%',
	},

	/* Extra large devices (large laptops and desktops, 1200px and up) */
	'@media only screen and (min-width: 1200px)': {
		width: '55%',
	},
});

export const MenuItemLogout = styled(Box)({
	marginTop: '20vh',
	'@media only screen and (max-width: 600px)': {
		width: '70%',
	},

	/* Small devices (portrait tablets and large phones, 600px and up) */
	'@media only screen and (min-width: 600px)': {
		width: '75%',
	},

	/* Medium devices (landscape tablets, 768px and up) */
	'@media only screen and (min-width: 768px)': {
		width: '60%',
	},

	/* Large devices (laptops/desktops, 992px and up) */
	'@media only screen and (min-width: 992px)': {
		width: '50%',
	},

	/* Extra large devices (large laptops and desktops, 1200px and up) */
	'@media only screen and (min-width: 1200px)': {
		width: '40%',
	},
	height: '80px',
	display: 'flex',
	flexDirection: 'row',
	justifyContent: 'space-between',
	alignItems: 'center',
	marginLeft: 'auto',
	marginRight: 'auto',
	padding: '20px',
	borderWidth: '1px',
	borderRadius: '55px',
	borderColor: 'black',
	cursor: 'pointer',
	':active': {
		borderColor: 'rgb(171, 171, 171)',
		borderWidth: '1px',
		backgroundColor: '#c7a985a3',
		borderStyle: 'solid',
	},
});

export const BtnSettingsText = styled(Box)({
	paddingLeft: '14px',
	fontSize: '20px',
	fontWeight: 'bold',
	width: '100vw',
	textAlign: 'left',
});

export const LabelText = styled(InputLabel)({
	fontSize: '20px',
	fontWeight: 'bold',
	textAlign: 'left',
});

export const FooterSettings = styled(Box)({
	height: '10vh',
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
});

export const LanguageBox = styled(Box)({
	height: '10vh',
	flexDirection: 'row',
});

export const BtnLanguage = styled(Box)({
	fontSize: '14px',
	width: '66px',
	marginLeft: 'auto',
	marginRight: 'auto',
	cursor: 'pointer',
	':active': {
		backgroundColor: '#c7a985a3',
	},
});

export const BtnCnxn = styled(Box)({
	minWidth: '25vw',
	marginLeft: 'auto',
	marginRight: 'auto',
	paddingTop: 25,
});
