import React, { createContext, useState, useContext, useEffect } from 'react';
import axios from '../utils/api';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';

const AuthContext = createContext();

const STORAGE_AUTH_KEY = 'auth';

const AuthProvider = ({ children }) => {
	const i18n = useTranslation();

	const { enqueueSnackbar } = useSnackbar();

	const [credentials, setCredentials] = useState({
		user: localStorage.getItem(STORAGE_AUTH_KEY)
			? JSON.parse(localStorage.getItem(STORAGE_AUTH_KEY)).user
			: null,
		token: localStorage.getItem(STORAGE_AUTH_KEY)
			? JSON.parse(localStorage.getItem(STORAGE_AUTH_KEY)).token
			: null,
	});

	useEffect(() => {
		if (credentials !== null) {
			localStorage.setItem(STORAGE_AUTH_KEY, JSON.stringify(credentials));
		}
	}, [credentials]);

	useEffect(() => {
		if (credentials.token !== null) {
			const myInterceptor = axios.interceptors.request.use(
				async (config) => {
					config.headers = {
						authorization: `Bearer ${credentials.token}`,
					};

					return config;
				},
				(error) => {
					if (error.response.status === 401) {
						enqueueSnackbar(`${i18n.t('nonValidSession')}`, {
							variant: 'warning',
						});
						setTimeout(() => {
							setCredentials({
								user: null,
								token: null,
							});
						}, 2000);
					}
				}
			);

			return () => axios.interceptors.request.eject(myInterceptor);
		}
	}, [credentials.token, enqueueSnackbar, i18n]);

	const signIn = async (values) => {
		const result = await axios.post('/authenticate', {
			email: values.email,
			password: values.password,
		});

		setCredentials({
			user: values.email,
			token: result.data.token,
		});

		return result;
	};

	const signOut = async () => {
		setCredentials({
			user: null,
			token: null,
		});
	};

	return (
		<AuthContext.Provider
			value={{
				isSigned: credentials.token !== null && credentials.token !== undefined,
				credentials: credentials,
				signIn,
				signOut,
			}}
		>
			{children}
		</AuthContext.Provider>
	);
};

export const useAuth = () => useContext(AuthContext);

export { AuthContext, AuthProvider };
