import React from 'react';
import { Separator, MainRemote, Segmentbuttons, FooterRemote } from './styles';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import FunctionsIcon from '@mui/icons-material/Functions';
import SegmentIcon from '@mui/icons-material/Segment';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import UndoIcon from '@mui/icons-material/Undo';
import CancelIcon from '@mui/icons-material/Cancel';
import DeleteIcon from '@mui/icons-material/Delete';
import AppsIcon from '@mui/icons-material/Apps';
import TimerIcon from '@mui/icons-material/Timer';
import { CommandButton, BottomNavigations } from '../../components';
import { useTranslation } from 'react-i18next';

function Remote() {
	const { i18n } = useTranslation();

	return (
		<>
			<Separator />
			<MainRemote>
				<Segmentbuttons>
					<CommandButton
						id="1"
						text={`${i18n.t('previousBtn')}`}
						icon={<ArrowBackIosIcon />}
					/>

					<CommandButton
						id="3"
						text={`${i18n.t('nextBtn')}`}
						icon={<ArrowForwardIosIcon />}
						alignRight
					/>
				</Segmentbuttons>

				<Segmentbuttons>
					<CommandButton
						id="5"
						text={`${i18n.t('resultsComulatedBtn')}`}
						icon={<FunctionsIcon />}
					/>

					<CommandButton
						id="2"
						text={`${i18n.t('resultsInstantBtn')}`}
						icon={<SegmentIcon />}
						alignRight
					/>
				</Segmentbuttons>

				<Segmentbuttons>
					<CommandButton
						id="11"
						text={`${i18n.t('rankingBtn')}`}
						icon={<TrendingUpIcon />}
					/>

					<CommandButton
						id="6"
						text={`${i18n.t('hideResultsBtn')}`}
						icon={<UndoIcon />}
						alignRight
					/>
				</Segmentbuttons>

				<Segmentbuttons>
					<CommandButton
						id="7"
						text={`${i18n.t('timerBtn')}`}
						icon={<TimerIcon />}
					/>

					<CommandButton
						id="15"
						text={`${i18n.t('deleteResponsesBtn')}`}
						icon={<DeleteIcon />}
						alignRight
					/>
				</Segmentbuttons>

				<Segmentbuttons>
					<CommandButton
						id="9"
						text={`${i18n.t('roomPlanBtn')}`}
						icon={<AppsIcon />}
					/>

					<CommandButton
						id="13"
						text={`${i18n.t('quit')}`}
						icon={<CancelIcon />}
						alignRight
					/>
				</Segmentbuttons>
			</MainRemote>

			<FooterRemote>
				<BottomNavigations current="remote" />
			</FooterRemote>
		</>
	);
}

export default Remote;
