import React, { useContext } from 'react';
import {
	BrowserRouter as Router,
	Route,
	Routes,
	Navigate,
} from 'react-router-dom';
import { Main, Participants, Settings, Remote } from '../../screens';
import { currentSessionContext } from '../../contexts/currentSession';
import { useCurrentSessionAutomaticUpdate } from '../../hooks';
import { TopBar } from '../../components';

const AuthenticatedApp = () => {
	const { currentSession } = useContext(currentSessionContext);

	useCurrentSessionAutomaticUpdate();

	return (
		<>
			<TopBar codeOnline={currentSession?.codeOnline} />
			<>
				<Router>
					<Routes>
						<Route path="/" element={<Navigate to="/main" />}></Route>
						<Route path="/main" element={<Main />}></Route>
						<Route path="/participants" element={<Participants />}></Route>
						<Route path="/settings" element={<Settings />}></Route>
						<Route path="/remote" element={<Remote />}></Route>
					</Routes>
				</Router>
			</>
		</>
	);
};

export default AuthenticatedApp;
