import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import { styled } from '@mui/material/styles';

export const RemoteButton = styled(Box)(({ boxShadowIsActive }) => ({
	marginBottom: '0.3rem',
	borderRadius: '25px',
	boxShadow: boxShadowIsActive ? '2px 2px 10px 0px #c7c7c7' : 'none',
	'&:active': {
		borderColor: 'rgb(171, 171, 171)',
		borderWidth: '1px',
		backgroundColor: '#c7a985a3',
		borderStyle: 'solid',
	},
	'&:hover': {
		cursor: 'pointer',
	},
	'@media (min-width: 768px)': {
		marginBottom: '1.3rem',
	},
	'@media (min-width: 1040px)': {
		marginRight: '1rem',
		marginLeft: '1rem',
	},
}));

export const RemoteButtonElement = styled(Box)({
	width: '40vw',
	height: '11vh',
	display: 'flex',
	flexDirection: 'column',
	justifyContent: 'center',
	transition: 'background-color 1s ease-out 50ms',
	'@media (min-width: 768px)': {
		width: '35vw',
		height: '10vh',
	},
	'@media (min-width: 1040px)': {
		width: '20vw',
	},
});

export const BtnElement = styled(Box)({
	borderRadius: '10px',
	alignItems: 'center',
});

export const BtnText = styled(InputLabel)({
	fontSize: '3.7vw',
	fontFamily: 'source sans pro, Roboto, Helvetica, Arial',
	color: 'orange',
	textAlign: 'center',
	'&:hover': {
		cursor: 'pointer',
	},
	'@media (min-width: 768px)': {
		fontSize: '2.7vw',
	},
	'@media (min-width: 1040px)': {
		fontSize: '1.5vw',
	},
});
