import React from 'react';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import { keyframes } from '@mui/system';

const LoadingSpinner = () => {
	const spinner = keyframes`
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}`;

	const LoadingSpinner = styled(Box)({
		width: 50,
		height: 50,
		border: '10px solid #f3f3f3',
		borderTop: '10px solid #383636',
		borderRadius: '50%',
		animation: `${spinner} 1.5s linear infinite`,
	});

	return <LoadingSpinner></LoadingSpinner>;
};

export default LoadingSpinner;
