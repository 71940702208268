import { useEffect } from 'react';

async function useInterval({ callback, delay = 5000, callOnInit = false }) {
	useEffect(() => {
		if (delay !== null) {
			if (callOnInit) {
				callback();
			}

			let id = setInterval(callback, delay);
			return () => clearInterval(id);
		}
	}, [callback, callOnInit, delay]);
}

export default useInterval;
