import React from 'react';
import { createTheme } from '@mui/material/styles';
import { colors } from '@mui/material';
import { ThemeContext } from '../contexts/themeContext';

const useTheme = () => {
	const { currentTheme } = React.useContext(ThemeContext);

	const theme = createTheme({
		palette: {
			background: {
				default: currentTheme === 'light' ? '#F5F5F5' : '#000000',
			},
			mode: currentTheme,
			primary: {
				main: currentTheme === 'light' ? colors.orange[500] : '#fafafa',
			},
			secondary: { main: colors.orange[500] },
			buttons: {
				main: colors.orange[700],
			},
			buttonsTextColors: {
				main: currentTheme === 'light' ? 'black' : 'white',
			},
			backGround: {
				main: currentTheme === 'light' ? '#fff' : '#424242',
			},
			bottomNavigator: {
				main: currentTheme === 'light' ? 'lightgray' : '#424242',
			},
			snackBarSuccess: '#3CB371',
			snackBarError: 'red',
			errorMsg: 'red',
		},
		components: {
			MuiCssBaseline: {
				styleOverrides: {
					body: {
						textAlign: 'center',
					},
				},
			},
		},
	});

	return theme;
};

export default useTheme;
