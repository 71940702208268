import React, { createContext, useState } from 'react';

const currentSessionContext = createContext();

function CurrentSessionProvider({ children }) {
	const [currentSession, setCurrentSession] = useState({});

	return (
		<currentSessionContext.Provider
			value={{ currentSession: currentSession, setCurrentSession }}
		>
			{children}
		</currentSessionContext.Provider>
	);
}

export { CurrentSessionProvider, currentSessionContext };
